import React from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Notice from '../components/Notice';

import heroImg from '../../static/images/hero_image.webp';

const FullLayout = ({ children, seo }) => (
  <>
    {seo}
    <Notice />
    <Navbar transparent />
    <main>
      <div className="relative pt-16 pb-32 flex content-center items-center justify-center"
        style={{
          minHeight: "75vh"
        }}>
        <div className="absolute top-0 w-full h-full bg-center bg-cover"
          style={{
            backgroundImage: `url(${heroImg})`
          }}>
          <span id="blackOverlay" className="w-full h-full absolute opacity-75 bg-black"></span>
        </div>
        <div className="container relative mx-auto">
          <div className="items-center flex flex-wrap">
            <div
              data-sal="slide-up"
              data-sal-delay="100"
              data-sal-easing="ease-in-out"
              className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
              <div className="py-5">
                <h1 className="text-white font-semibold text-5xl">
                  IBAN Accounts for
                </h1>
                <h1 className="text-white font-semibold text-5xl">
                  Businesses
                </h1>
                <p className="mt-4 mb-4 text-lg text-gray-300">
                  Interested in opening a European Business Payment Account?
                </p>
                <div className="mt-10 text-center">
                  <a
                    href="https://enquire.finxp.com/en/home"
                    className="bg-pink-600 hover:bg-pink-600 text-white font-bold uppercase text-base px-8 py-3 rounded shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                    style={{ transition: 'all .15s ease' }}
                  >
                    Sign Up
                  </a>
                </div>
              </div>

            </div>

          </div>
        </div>
        <div
          className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
          style={{ height: "70px", transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-white fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </div>

      {children}
    </main>

    <Footer />
  </>
);

export default FullLayout;
